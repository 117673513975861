@import "https://fonts.googleapis.com/css?family=Allura:400|Inria+Sans:400,italic|Inter:var(--body-lg-regular-font-weight),var(--body-sm-semibold-font-weight),var(--display-lg-bold-font-weight),700,var(--body-md-semibold-font-weight),var(--body-xs-semibold-font-weight),300,var(--display-xl-bold-font-weight),400,500|Source+Sans+3:400|Source+Sans+Pro:700,400";
@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@font-face {
  font-family: Calibri-Regular;
  src: url("https://anima-uploads.s3.amazonaws.com/projects/609bac86cef20771db431fdc/fonts/calibri.ttf") format("truetype");
}

@font-face {
  font-family: Calibri-Italic;
  src: url("https://anima-uploads.s3.amazonaws.com/5c7d32df985a58000abba4ac/Calibri Italic.ttf") format("truetype");
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html {
  font-size: 40px;
}

a {
  text-decoration: none;
}

:root {
  --screen-height-px: 1080px;
  --screen-width-px: 1920px;
}
/*# sourceMappingURL=index.82c46787.css.map */
