@import url("https://fonts.googleapis.com/css?family=Allura:400|Inria+Sans:400,italic|Inter:var(--body-lg-regular-font-weight),var(--body-sm-semibold-font-weight),var(--display-lg-bold-font-weight),700,var(--body-md-semibold-font-weight),var(--body-xs-semibold-font-weight),300,var(--display-xl-bold-font-weight),400,500|Source+Sans+3:400|Source+Sans+Pro:700,400");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
    font-family: "Calibri-Regular";
    src: url("https://anima-uploads.s3.amazonaws.com/projects/609bac86cef20771db431fdc/fonts/calibri.ttf") format("truetype");
}

@font-face {
    font-family: "Calibri-Italic";
    src: url("https://anima-uploads.s3.amazonaws.com/5c7d32df985a58000abba4ac/Calibri Italic.ttf") format("truetype");
}

* {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

html {
    font-size: 40px;
}

/* html,
body {
    margin: 0px;
    height: 100%;
} */

/* a blue color as a generic focus style */
/* button:focus-visible {
    outline: 2px solid #4a90e2 !important;
    outline: -webkit-focus-ring-color auto 5px !important;
} */

a {
    text-decoration: none;
}
/* global.css */
:root {
    --screen-height-px: 1080px;
    --screen-width-px: 1920px;
}